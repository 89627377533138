import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLogged } from '#store';
import { WatcherManager } from '#components';
import { Routes } from '#Routes';

const ProtectedPage = () => {
  const isLogged = useSelector(selectIsLogged);
  const location = useLocation();

  if (!isLogged) {
    // Extract language from URL or query params, default to 'pl'
    const languageFromQuery = new URLSearchParams(location.search).get('language');
    const language = languageFromQuery || 'pl';

    return (
      <Navigate
        to={Routes.Login.withLanguageAndRedirect(
          language,
          encodeURIComponent(location.pathname + location.search),
        )}
      />
    );
  }

  return (
    <>
      <WatcherManager />
      <Outlet />
    </>
  );
};

export default ProtectedPage;
