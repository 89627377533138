import React, { useEffect } from 'react';
import { Row, Col, Card, Image, Button } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Routes } from '#Routes';
import { PlusIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

import { MedicalDocumentsListWidget } from '#components/widgets/MedicalDocumentsWidget';
import { HealthReportsListWidget } from '#components/widgets/HealthReportsWidget';

const DashboardPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const language = searchParams.get('language');
    if (language && ['en', 'pl'].includes(language)) {
      i18n.changeLanguage(language);
    }
  }, [searchParams, i18n]);

  return (
    <>
      <Row className='mb-4'>
        <Col>
          <Card border='0' className='shadow'>
            <Card.Body className='d-flex flex-column align-items-center justify-content-center border-bottom'>
              {/* <Image src={Logo} className='image-md' /> */}
              <Image src='/logo_new_2.svg' className='image-md' />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col xs={12} md={6} xxl={6} className='mb-4'>
          <Button
            onClick={() => {
              navigate(Routes.MedicalDocumentCreate.path);
            }}
            variant='logo-green'
            className='text-center'
          >
            <PlusIcon className='icon icon-xs me-2' /> {t('dashboard.add-new-document')}
          </Button>
        </Col>
        <Col xs={12} md={6} xxl={6} className='mb-4'>
          <Button
            onClick={() => {
              navigate(Routes.HealthReportCreate.path);
            }}
            variant='logo-green'
            className='text-center'
          >
            <PlusIcon className='icon icon-xs me-2' /> {t('dashboard.create-new-report')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} xxl={6} className='mb-4'>
          <MedicalDocumentsListWidget />
        </Col>

        <Col xs={12} md={6} xxl={6} className='mb-4'>
          <HealthReportsListWidget />
        </Col>
      </Row>
    </>
  );
};

export default DashboardPage;
