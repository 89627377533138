import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SubscriptionCheckout, ProductsPricing } from '#components';

export default function SubscriptionPage() {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  const [selectedProduct, setSelectedProduct] = useState(null);
  const unselectProduct = useCallback(() => setSelectedProduct(null), [setSelectedProduct]);

  useEffect(() => {
    const language = searchParams.get('language');
    if (language && ['en', 'pl'].includes(language)) {
      i18n.changeLanguage(language);
    }
  }, [searchParams, i18n]);

  return (
    <>
      <div className='d-block py-4'>
        <h3 className='text-center'> {t('subscription.title')}</h3>
        <p className='mb-0 text-center'>{t('subscription.subtitle')}</p>
      </div>

      <ProductsPricing showActiveProducts onSelectProduct={setSelectedProduct} />

      <SubscriptionCheckout product={selectedProduct} onUnselectProduct={unselectProduct} />
    </>
  );
}
