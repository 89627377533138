import React from 'react';
import { Card, Alert, Container, Row, Col } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon } from '@heroicons/react/solid';

export default function AccountCreatedPage() {
  const { t } = useTranslation();

  return (
    <main>
      <section className='vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center'>
        <Container>
          <Row className='justify-content-center'>
            <Col xs={12} className='d-flex align-items-center justify-content-center'>
              <div className='bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                <div className='text-center mb-4'>
                  <CheckCircleIcon className='icon icon-xl text-success mb-3' />
                  <h3 className='mb-3'>{t('register.account-created')}</h3>
                  <Alert variant='success' className='mb-4'>
                    {t('register.messages.account-created-info')}
                  </Alert>
                </div>

                <div className='d-grid gap-3'>
                  <Card.Link
                    as={Link}
                    to={generatePath('/auth/login')}
                    className='btn btn-gray-800'
                  >
                    {t('register.login-here')}
                  </Card.Link>

                  <Card.Link as={Link} to={generatePath('/')} className='btn btn-outline-secondary'>
                    {t('register.back-to-homepage')}
                  </Card.Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}
