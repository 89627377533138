import React, { useState } from 'react';
import { Card, Col, Row, ListGroup, Button, Badge, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Routes } from '#Routes';
import { useChangeNameMutation } from '#store';

const AccountDetails = () => {
  const { t } = useTranslation();
  const patient = useSelector((state) => state.patient);
  const isEmailVerified = useSelector((state) => state.auth.isEmailVerified);

  const [name, setName] = useState(patient.name);
  const [isEditing, setIsEditing] = useState(false);

  const [changeName] = useChangeNameMutation();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const saveName = async () => {
    try {
      await changeName({ name }).unwrap();
      setIsEditing(false);
      toast.error(t('auth.name-toast-success'));
    } catch (error) {
      console.error('Failed to change name:', error);
      toast.error(t('auth.name-toast-error'));
      setIsEditing(false);
    }
  };

  return (
    <Card as='form' border='0' className='shadow p-0 p-md-4 h-100'>
      <Card.Header className='border-bottom p-3'>
        <h5 className='mb-0'>{t('account.account-details')}</h5>
      </Card.Header>
      <Card.Body className='px-0 py-0'>
        <ListGroup>
          <ListGroup.Item className='py-3 border-bottom'>
            <Row className='align-items-center'>
              <Col>
                <h6 className='mb-1'>{t('account.email')}</h6>
              </Col>
              <Col xs='auto'>
                <b>{patient.email}</b>
                {!isEmailVerified && (
                  <Badge bg='warning' className='ms-1'>
                    {t('account.emailUnverified')}
                  </Badge>
                )}
              </Col>
              {!isEmailVerified && (
                <Col xs='auto'>
                  <Button as={Link} to={Routes.ResendEmailVerification.path}>
                    {t('auth.resendEmailVerification')}
                  </Button>
                </Col>
              )}
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className='py-3 border-bottom'>
            <Row className='align-items-center'>
              <Col>
                <h6 className='mb-1'>{t('account.password')}</h6>
              </Col>
              <Col xs='auto'>
                <Button as={Link} variant='logo-green' to={Routes.ChangePassword.path}>
                  {t('auth.changePassword')}
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className='py-3'>
            <Row className='align-items-center'>
              <Col>
                <h6 className='mb-1'>{t('auth.name')}</h6>
                {isEditing ? (
                  <Form.Control type='text' value={name} onChange={handleNameChange} />
                ) : (
                  <p>{name}</p>
                )}
              </Col>
              <Col xs='auto'>
                {isEditing ? (
                  <Button variant='logo-green' onClick={saveName}>
                    {t('auth.name-save')}
                  </Button>
                ) : (
                  <Button variant='logo-green' onClick={() => setIsEditing(true)}>
                    {t('auth.name-edit')}
                  </Button>
                )}
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default React.memo(AccountDetails);
