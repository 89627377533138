import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowNarrowLeftIcon, LockClosedIcon } from '@heroicons/react/solid';
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Collapse,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorAlert } from '#atoms';
import { Routes } from '#Routes';
import { useCustomForm } from '#hooks';
import { useChangePasswordMutation } from '#store';

const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    translateFormError,
    formState: { errors },
  } = useCustomForm();

  const [changePassword, { isLoading, isSuccess, isError, error: rawError }] =
    useChangePasswordMutation();

  const error = useMemo(() => {
    if (!rawError) return undefined;
    return rawError?.status === 400
      ? rawError.data.old_password ||
          rawError.data.new_password1 ||
          rawError.data.new_password2 ||
          rawError.data
      : ['Unexpected error'];
  }, [rawError]);

  useEffect(() => {
    if (isSuccess) {
      const timeoutId = setTimeout(() => {
        navigate(Routes.Account.path);
      }, 5000);

      return () => {
        clearInterval(timeoutId);
      };
    }
  }, [navigate, isSuccess]);

  return (
    <main>
      <section className='d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft'>
        <Container>
          <Row className='justify-content-center'>
            <p className='text-center'>
              <Card.Link
                as={Link}
                to={Routes.Account.path}
                className='d-flex align-items-center justify-content-center'
              >
                <ArrowNarrowLeftIcon className='icon icon-xs me-2' />
                {t('auth.backToAccount')}
              </Card.Link>
            </p>
            <Col xs={12} className='d-flex align-items-center justify-content-center'>
              <div className='bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500'>
                <h3 className='mb-4'>{t('auth.changePassword')}</h3>

                <Form onSubmit={handleSubmit(changePassword)}>
                  <Form.Group id='password' className='mb-4'>
                    <Form.Label>{t('auth.password')}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className='icon icon-xs text-gray-600' />
                      </InputGroup.Text>
                      <Form.Control
                        type='password'
                        placeholder={t('auth.password')}
                        autoComplete='password'
                        isInvalid={!!errors.password}
                        {...register('oldPassword', { required: true, minLength: 8 })}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {translateFormError('oldPassword')}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id='newPassword' className='mb-4'>
                    <Form.Label>{t('auth.passwordNew')}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className='icon icon-xs text-gray-600' />
                      </InputGroup.Text>
                      <Form.Control
                        type='password'
                        placeholder={t('auth.passwordNew')}
                        autoComplete='new-password'
                        isInvalid={!!errors.newPassword1}
                        {...register('newPassword1', { required: true, minLength: 8 })}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {translateFormError('newPassword1')}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id='confirmNewPassword' className='mb-4'>
                    <Form.Label>{t('auth.passwordNewConfirm')}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <LockClosedIcon className='icon icon-xs text-gray-600' />
                      </InputGroup.Text>
                      <Form.Control
                        type='password'
                        placeholder={t('auth.passwordNewConfirm')}
                        autoComplete='new-password'
                        isInvalid={!!errors.newPassword2}
                        {...register('newPassword2', { required: true, minLength: 8 })}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {translateFormError('newPassword2')}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  {isSuccess && (
                    <Collapse in={true} appear={true}>
                      <div className='d-grid'>
                        <Alert variant='success'>{t(`auth.changePasswordSuccessPrompt`)}</Alert>
                      </div>
                    </Collapse>
                  )}

                  {!isSuccess && (
                    <>
                      {isError && <ErrorAlert error={error} />}

                      <div className='d-grid'>
                        <Button variant='primary' size='lg' type='submit' disabled={isLoading}>
                          {isLoading ? <Spinner size='sm' /> : t('auth.changePasswordSubmit')}
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ChangePasswordPage;
