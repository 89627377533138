import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DocumentViewer from '#components/DocumentViewer';

const DisclaimersPage = () => {
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    const language = searchParams.get('language');
    if (language && ['en', 'pl'].includes(language)) {
      i18n.changeLanguage(language);
    }
  }, [searchParams, i18n]);

  return (
    <main>
      <DocumentViewer
        fileTranslationKey='disclaimers.file'
        titleTranslationKey='disclaimers.title'
      />
    </main>
  );
};

export default DisclaimersPage;
