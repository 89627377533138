import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import { Card, Col, Row, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetchMedicalDocumentsQuery } from '#store';
import {
  MedicalDocumentsList,
  MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS,
  MedicalDocumentsFilters,
  MEDICAL_DOCUMENTS_FILTERS_TYPES,
  Pagination,
} from '#components';
import { Routes } from '#Routes';

const LIMIT_PER_PAGE = 5;

const MedicalDocumentsListPage = ({ limit = LIMIT_PER_PAGE }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({});

  const offset = currentPage * limit;

  const query = {
    offset,
    limit,
  };

  if (filters.tags?.length > 0) query.tags = filters.tags;
  if (filters.document_type) query.document_type = filters.document_type;
  if (filters.confirmed) query.confirmed = filters.confirmed;

  const { data, isLoading } = useFetchMedicalDocumentsQuery(query, {
    refetchOnMountOrArgChange: true,
  });

  const totalItems = data?.count ?? 0;

  const pageNumbers = Math.ceil(totalItems / limit);

  const pages = Array.from(Array(pageNumbers).keys());

  const onPageChange = (p) => setCurrentPage(p);

  const onNewFilters = (newFilters) => {
    const params = { ...query, ...newFilters };
    setFilters(params);
  };

  const onTagClick = (tag) => {
    const params = { ...query };
    if (params.tags) {
      if (!params.tags.find((t) => t === tag)) {
        params.tags = [...params.tags, tag];
      }
    } else {
      params.tags = [tag];
    }
    setFilters(params);
  };

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block'>
          <h4>{t('medical-documents-list.title')}</h4>
          <p className='mb-0'>{t('medical-documents-list.subtitle')}</p>
        </div>
      </div>

      <div className='table-settings mb-3'>
        <Row className='justify-content-between align-items-center flex-column-reverse flex-md-row'>
          <Col>
            <MedicalDocumentsFilters
              onChange={onNewFilters}
              filters={filters}
              display={[
                MEDICAL_DOCUMENTS_FILTERS_TYPES.TAGS,
                MEDICAL_DOCUMENTS_FILTERS_TYPES.DOCUMENT_TYPE,
                MEDICAL_DOCUMENTS_FILTERS_TYPES.CONFIRMED,
              ]}
            />
          </Col>
          <Col xs={12} md={4} lg={3} xl={2}>
            <div className='mb-3 mb-md-0 d-grid'>
              <Button
                onClick={() => {
                  navigate(Routes.MedicalDocumentCreate.path);
                }}
                variant='logo-green'
                className='text-center'
              >
                <PlusIcon className='icon icon-xs me-2' />
                {t('medical-documents-list.add-new-document')}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <Card border='0' className='table-wrapper shadow overflow-hidden'>
        <Card.Body>
          {isLoading ? (
            <div className='d-flex justify-content-center px-8 py-8'>
              <Spinner />
            </div>
          ) : (
            <>
              <MedicalDocumentsList
                data={data}
                onTagClick={onTagClick}
                display={[...Object.keys(MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS)].filter(
                  (key) => key !== MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.SELECT,
                )}
              />
              <Card.Footer className='px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between'>
                <Pagination
                  pages={pages}
                  limit={LIMIT_PER_PAGE}
                  pageNumbers={pageNumbers}
                  totalItems={totalItems}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                />
              </Card.Footer>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(MedicalDocumentsListPage);
