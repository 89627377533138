import React from 'react';
import moment from 'moment';
// import { CogIcon, ShoppingCartIcon } from '@heroicons/react/solid';
import { Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';

export const Footer = (props) => {
  const { t } = useTranslation();
  const currentYear = moment().get('year');

  return (
    <div>
      <footer className='bg-white rounded shadow p-5 mb-4 mt-4'>
        <Row>
          <Col xs={12} md={4} xl={6} className='mb-4 mb-md-0'>
            <p className='mb-0 text-center text-lg-start'>
              © 2023-{`${currentYear} `}
              <Card.Link href='#' target='_blank' className='text-primary fw-normal'>
                <Image src='/text_2.svg' style={{ width: '10%', minWidth: '50px' }} />
                <small>{t('footer.copyright_text')}</small>
              </Card.Link>
            </p>
          </Col>
          {/* <Col xs={12} md={8} xl={6} className='text-center text-lg-start'>
            <ul className='list-inline list-group-flush list-group-borderless text-md-end mb-0'>
              <li className='list-inline-item px-0 px-sm-2'>
                <Card.Link href='#' target='_blank'>
                  About
                </Card.Link>
              </li>
              <li className='list-inline-item px-0 px-sm-2'>
                <Card.Link href='#' target='_blank'>
                  AI
                </Card.Link>
              </li>
              <li className='list-inline-item px-0 px-sm-2'>
                <Card.Link href='#' target='_blank'>
                  Blog
                </Card.Link>
              </li>
              <li className='list-inline-item px-0 px-sm-2'>
                <Card.Link href='mailto:hello@medify.me' target='_blank'>
                  Contact
                </Card.Link>
              </li>
            </ul>
          </Col> */}
        </Row>
      </footer>
    </div>
  );
};
