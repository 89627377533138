import React from 'react';
import {
  // BellIcon,
  // CogIcon,
  // InboxIcon,
  MenuAlt1Icon,
  // SearchIcon,
  // SupportIcon,
  // UserCircleIcon,
  // CollectionIcon,
  // CloudUploadIcon,
  // FireIcon,
  // PlusIcon,
  // ShieldExclamationIcon,
  // UserAddIcon,
  UserIcon,
} from '@heroicons/react/solid';
import { LogoutIcon } from '@heroicons/react/outline';
import {
  // Row,
  // Col,
  Nav,
  // Form,
  // Image,
  Button,
  Navbar,
  Dropdown,
  Container,
  // ListGroup,
  // InputGroup,
} from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { useAuth } from '#hooks';

export const Topbar = (props) => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const patient = useSelector((state) => {
    return state.patient;
  });

  const toggleContracted = () => props.toggleContracted && props.toggleContracted();

  // const onLogout = async () => {
  //   try {
  //     const token = userContext.auth_token;
  //     setAuthTokenInAxiosHeader(token);
  //     await logoutApiCall();
  //     setUserContext({ auth_token: "", account: { email: "", password: "" } });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  return (
    <Navbar expand variant='dark' className='navbar-top navbar-dashboard ps-0 pe-2 pb-0'>
      <Container fluid className='px-0'>
        <div className='d-flex justify-content-between w-100 mb-3'>
          <div className='d-flex align-items-center'>
            <Button
              size='lg'
              id='sidebar-toggle'
              variant='icon-only'
              className='sidebar-toggle d-none d-lg-inline-block align-items-center justify-content-center me-3'
              onClick={toggleContracted}
            >
              <MenuAlt1Icon className='toggle-icon' />
            </Button>
            <div className='py-4 d-flex'></div>
          </div>

          <Nav className='align-items-center'>
            <Dropdown as={Nav.Item} className='ms-lg-3'>
              <Dropdown.Toggle as={Nav.Link} className='pt-1 px-0'>
                <div className='media d-flex align-items-center'>
                  <UserIcon className='icon icon-md text-gray-400 me-2' />
                  <div className='media-body ms-2 text-dark align-items-center d-none d-lg-block'>
                    <span className='mb-0 font-small fw-bold text-gray-900'>
                      Hello {patient.name}
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className='dashboard-dropdown dropdown-menu-end mt-2 py-1'>
                {/* <Dropdown.Item className='d-flex align-items-center'>
                  <UserCircleIcon className='dropdown-icon text-gray-400 me-2' />{' '}
                  {t('account.my-profile')}
                </Dropdown.Item>
                <Dropdown.Item className='d-flex align-items-center'>
                  <CogIcon className='dropdown-icon text-gray-400 me-2' /> {t('account.settings')}
                </Dropdown.Item>
                <Dropdown.Item className='d-flex align-items-center'>
                  <InboxIcon className='dropdown-icon text-gray-400 me-2' /> {t('account.messages')}
                </Dropdown.Item>
                <Dropdown.Item className='d-flex align-items-center'>
                  <SupportIcon className='dropdown-icon text-gray-400 me-2' />{' '}
                  {t('account.support')}
                </Dropdown.Item>

                <Dropdown.Divider as='div' className='my-1' /> */}

                <Dropdown.Item className='d-flex align-items-center' onClick={logout}>
                  <LogoutIcon className='dropdown-icon text-danger me-2' /> {t('account.logout')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
