import React from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetchHealthReportsQuery } from '#store';
import { HealthReportsGallery } from '#components';
import { Routes } from '#Routes';

const HealthReportsListPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isLoading } = useFetchHealthReportsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <div>
      <div className='d-block mb-4'>
        <h4>{t('health-report-list-page.header')}</h4>
        <p className='mb-0'>{t('health-report-list-page.text')}</p>
      </div>
      <Row className='justify-content-between align-items-center mb-4'>
        <Col xs={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className='d-grid'>
          <Button
            variant='logo-green'
            size='lg'
            onClick={() => {
              navigate(Routes.HealthReportCreate.path);
            }}
            className='btn-block d-flex align-items-center justify-content-center'
          >
            <PlusIcon className='icon icon-xs me-2' /> {t('health-report-list-page.create-button')}
          </Button>
        </Col>
      </Row>
      <div className='mb-4'>
        {isLoading ? (
          <div className='d-flex justify-content-center align-items-center m-5'>
            <Spinner />
            <span className='ms-2'>{t('health-report-list-page.loading')}</span>
          </div>
        ) : (
          <HealthReportsGallery data={data?.results} />
        )}
      </div>
    </div>
  );
};

export default React.memo(HealthReportsListPage);
