import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';

const INITIAL_FORM_STATE = {
  name: '',
  email: '',
  subject: '',
  message: '',
};

const ContactForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData(INITIAL_FORM_STATE);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const { name, email, subject, message } = formData;
      const mailtoLink = `mailto:hello@medify.me?subject=${encodeURIComponent(
        subject,
      )}&body=${encodeURIComponent(
        `Name: ${name}\nAccount Email: ${email}\n\nMessage:\n${message}`,
      )}`;
      window.location.href = mailtoLink;
      resetForm();
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className='mb-4'>
        <Form.Label htmlFor='formName'>{t('contact-form.name')}</Form.Label>
        <Form.Control
          type='text'
          id='formName'
          name='name'
          value={formData.name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className='mb-4'>
        <Form.Label htmlFor='formEmail'>{t('contact-form.account-email')}</Form.Label>
        <Form.Control
          type='email'
          id='formEmail'
          name='email'
          value={formData.email}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className='mb-4'>
        <Form.Label htmlFor='formSubject'>{t('contact-form.subject')}</Form.Label>
        <Form.Control
          type='text'
          id='formSubject'
          name='subject'
          value={formData.subject}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className='mb-4'>
        <Form.Label htmlFor='formMessage'>{t('contact-form.message')}</Form.Label>
        <Form.Control
          as='textarea'
          id='formMessage'
          name='message'
          value={formData.message}
          onChange={handleChange}
          required
          rows={4}
        />
      </Form.Group>

      <Button variant='logo-green' type='submit' className='btn-block' disabled={isSubmitting}>
        {isSubmitting ? t('common.sending') : t('contact-form.send')}
      </Button>
    </Form>
  );
};

export default ContactForm;
