import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DocumentViewer from '#components/DocumentViewer';

const TermsPage = () => {
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    const language = searchParams.get('language');
    if (language && ['en', 'pl'].includes(language)) {
      i18n.changeLanguage(language);
    }
  }, [searchParams, i18n]);

  return (
    <main>
      <DocumentViewer fileTranslationKey='terms.file' titleTranslationKey='terms.title' />
    </main>
  );
};

export default TermsPage;
