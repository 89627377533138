import React, { useEffect } from 'react';
import { Alert, Button, Card, Form, Collapse, Spinner, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { ImageInput, SelectBox } from '#atoms';
import { LaboratoryForm, DocumentInfoForm, DiagnosisForm } from '#components';
import {
  useCreateMedicalDocumentMutation,
  useProcessMedicalDocumentMutation,
  useUpdateMedicalDocumentMutation,
  useFetchSubscriptionStatusQuery,
} from '#store';
import { TagsInput } from '#atoms';
import { alert } from '#utils';
import { Routes } from '#Routes';

let DocumentTypePrev;

export const MedicalDocumentForm = ({ type, data }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const form = useForm({
    values: data,
  });

  const {
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { dirtyFields },
  } = form;

  const [updateMedicalDocumentMutation, { isLoading: isLoadingUpdate }] =
    useUpdateMedicalDocumentMutation();
  const [createMedicalDocumentMutation, { isLoading: isLoadingCreate }] =
    useCreateMedicalDocumentMutation();
  const [processMedicalDocumentMutation] = useProcessMedicalDocumentMutation();

  const { data: subscriptionStatus } = useFetchSubscriptionStatusQuery();

  const DocumentType = watch('document_type');
  const DocumentImages = watch('document_images');
  const ProcessType = watch('process_type');

  useEffect(() => {
    if (DocumentType !== DocumentTypePrev) {
      reset({
        document_type: getValues('document_type'),
        document_images: getValues('document_images'),
        process_type: getValues('process_type'),
      });
    }
  }, [DocumentType, getValues, reset]);

  useEffect(() => {
    if (type !== 'EDIT') {
      const remainingCredits =
        subscriptionStatus?.creds + subscriptionStatus?.extra_credits - subscriptionStatus?.counts;

      if (subscriptionStatus && ProcessType === 'AI') {
        if (remainingCredits <= 0) {
          const isActiveSubsription = subscriptionStatus?.currentSubs?.length > 0;
          const title = isActiveSubsription
            ? t('document-form.actve-subscription-title1')
            : t('document-form.actve-subscription-title2');
          const text = isActiveSubsription
            ? t('document-form.actve-subscription-text1')
            : t('document-form.actve-subscription-text2');

          alert
            .fire({
              icon: 'warning',
              title,
              text,
              showCancelButton: true,
              confirmButtonText: t('document-form.no-ai-process-button-confirm'),
              cancelButtonText: t('document-form.no-ai-process-button-cancel'),
            })
            .then((result) => {
              setValue('process_type', result.isConfirmed ? 'MANUAL' : 'POSTPONE');
            });
        }
      }
    }
  }, [ProcessType, navigate, setValue, subscriptionStatus, type, t]);

  DocumentTypePrev = DocumentType;

  const getUpdatedValues = ({ fields = dirtyFields, values }) => {
    return Object.keys(fields).reduce((acc, field) => {
      if (fields[field]) acc[field] = values[field];
      return acc;
    }, {});
  };

  const onSubmit = async (params) => {
    let medicalDocument;

    if (params.json_content) {
      params.json_content = params.json_content.reduce((acc, data) => {
        if (data.icd) {
          data.values = data.values.filter((value) => value.name);
          acc.push(data);
        }

        return acc;
      }, []);

      params.json_content = params.json_content.map((lab) => {
        if (lab.icd.value) {
          const namePl = lab.icd.namePl || lab.namePl;
          const nameEn = lab.icd.nameEn || lab.nameEn;
          const currentLanguage = lab.icd.currentLanguage || lab.currentLanguage || i18n.language;
          return {
            ...lab,
            category_name: currentLanguage === 'pl' ? namePl : nameEn,
            icd: lab.icd.value,
            namePl,
            nameEn,
            currentLanguage,
          };
        }
        return lab;
      });
    }

    if (type === 'EDIT') {
      const { data: updatedDocument } = await updateMedicalDocumentMutation({
        id: params.id,
        ...getUpdatedValues({ values: params }),
      });
      medicalDocument = updatedDocument;
      if (updatedDocument) {
        alert.fire({
          icon: 'success',
          title: t('document-form.document-updated-title'),
          text: t('document-form.document-updated-text'),
          showButtonText: false,
          timer: 3000,
        });
      }
    } else {
      const { data: createdDocument } = await createMedicalDocumentMutation(params);
      medicalDocument = createdDocument;

      if (medicalDocument?.id) {
        if (medicalDocument.process_type === 'AI') {
          const documentImage = await processMedicalDocumentMutation({
            uuid: medicalDocument.uuid,
          });
          console.log('[AI] Processing document started: ', documentImage);
        }

        alert.fire({
          icon: 'success',
          title: t('document-form.document-created-title'),
          text: `${t('document-form.document-created-text1')} ${
            medicalDocument.process_type === 'AI' ? t('document-form.document-created-text2') : ''
          }`,
          showButtonText: false,
          timer: 3000,
        });
      }
    }

    if (medicalDocument?.id) {
      if (medicalDocument.process_type === 'MANUAL') {
        navigate(generatePath(Routes.MedicalDocument.path, { id: medicalDocument.id }));
      } else {
        navigate(generatePath(Routes.MedicalDocumentsList.path));
      }
    } else {
      // TODO: something went wrong
    }
  };

  const Images = () => (
    <Card className='mb-4'>
      <Card.Header className='text-center mb-3'>
        <h4>{t('document-form.document-images-header')}</h4>
      </Card.Header>
      <Card.Body>
        <ImageGallery
          showFullscreenButton={false}
          showPlayButton={false}
          showNav={false}
          items={data.images?.map((image) => {
            return {
              original: image.signed_url,
              thumbnail: image.thumbnail_signed_url,
            };
          })}
        />
      </Card.Body>
    </Card>
  );

  const DocumentInProgress = (
    <Card>
      <Card.Body className='d-flex flex-column align-items-center m-5'>
        <h4 className='m-4'>{t('document-form.processing-in-progress')}</h4>
        <Spinner
          className='m-4'
          style={{ width: '5rem', height: '5rem', fontSize: '2rem' }}
        ></Spinner>
      </Card.Body>
    </Card>
  );
  const DocumentTypes = [
    {
      name: t('document-types.diag'),
      value: 'DIAG',
    },
    {
      name: t('document-types.lab'),
      value: 'LAB',
    },
  ];

  const DocumentTypeForm = (
    <Card className='mb-4'>
      <Card.Header className='text-center mb-3'>
        <h4>{t('document-form.choose-type')}</h4>
      </Card.Header>
      <Card.Body>
        <SelectBox name='document_type' options={DocumentTypes} type='radio' form={form} />
      </Card.Body>
    </Card>
  );

  const DocumentImagesForm = (
    <Card className='mb-4'>
      <Card.Header className='text-center'>
        <h4>
          {DocumentType === DocumentTypes[0].value
            ? t('document-form.upload-image-diag')
            : t('document-form.upload-image-lab')}
        </h4>
      </Card.Header>
      <Card.Body>
        <ImageInput name='document_images' form={form} />
      </Card.Body>
      <Card.Footer>
        <Alert variant='light'>
          <Alert.Heading>{t('image.title')}</Alert.Heading>
          {t('image.warning')}
        </Alert>
      </Card.Footer>
    </Card>
  );

  const ProcessTypes = [
    {
      name: t('process-types.manual'),
      value: 'MANUAL',
    },
    {
      name: t('process-types.ai'),
      value: 'AI',
    },
    {
      name: t('process-types.postpone'),
      value: 'POSTPONE',
    },
  ];

  const ProcessingTypeForm = (
    <Card className='mb-4'>
      <Card.Body>
        <Card.Header className='text-center mb-3'>
          <h4>{t('document-form.choose-processing-type')}</h4>
        </Card.Header>
        <SelectBox name='process_type' options={ProcessTypes} type='radio' form={form} />
      </Card.Body>
    </Card>
  );

  const LabCreateForm = (
    <Row>
      <Col xs={6}>
        <LaboratoryForm form={form} data={data} />
      </Col>
      <Col xs={6}>
        <DocumentInfoForm form={form} data={data} />
      </Col>
    </Row>
  );

  const LabEditForm = (
    <Row>
      <Col xs={12}>
        <DocumentInfoForm form={form} data={data} />
      </Col>
      <Col xs={6}>
        {ProcessType === 'AI' && data?.process_status === 'IN_PROGRESS' ? (
          DocumentInProgress
        ) : (
          <LaboratoryForm form={form} data={data} />
        )}
      </Col>
      <Col xs={6}>
        <Images />
      </Col>
    </Row>
  );

  const DiagCreateForm = (
    <Row>
      <Col xs={6}>
        <DiagnosisForm form={form} />
      </Col>
      <Col xs={6}>
        <DocumentInfoForm form={form} data={data} />
      </Col>
    </Row>
  );

  const DiagEditForm = (
    <Row>
      <Col xs={12}>
        <DocumentInfoForm form={form} data={data} />
      </Col>
      <Col xs={6}>
        {ProcessType === 'AI' && data?.process_status === 'IN_PROGRESS' ? (
          DocumentInProgress
        ) : (
          <DiagnosisForm form={form} />
        )}
      </Col>
      <Col xs={6}>
        <Images />
      </Col>
    </Row>
  );

  const InfoCard = () => (
    <Card className='mb-4'>
      <Card.Body>
        <Alert variant='logo-light-green'>
          <Alert.Heading>{t('document-form.info-card-title')}</Alert.Heading>
          <p>{t('document-form.info-card-text')}</p>
        </Alert>
      </Card.Body>
    </Card>
  );

  return type === 'EDIT' && !data ? (
    <div className='d-flex justify-content-center py-8'>
      <Spinner animation='grow' style={{ width: '100px', height: '100px' }} />
    </div>
  ) : (
    <div>
      {type === 'EDIT' && <InfoCard />}
      <Form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
        {type === 'CREATE' ? (
          <>
            {DocumentTypeForm}
            <Collapse in={!!DocumentType}>{DocumentImagesForm}</Collapse>
            <Collapse in={DocumentImages?.length > 0}>{ProcessingTypeForm}</Collapse>
            <Collapse in={!!ProcessType}>
              <div>
                {ProcessType === 'MANUAL' ? (
                  <>{DocumentType === 'DIAG' ? DiagCreateForm : LabCreateForm}</>
                ) : (
                  <Card className='mb-4'>
                    <Card.Body>
                      <TagsInput form={form} initialValues={data?.tags} title={t('lab.tags')} />
                    </Card.Body>
                  </Card>
                )}
                <Row>
                  <Col xs={12} className='d-grid'>
                    <Button variant='logo-green' size='lg' type='submit' className='animate-up-2'>
                      {isLoadingUpdate || isLoadingCreate ? (
                        <Spinner />
                      ) : (
                        t('document-form.document-submit-button')
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </>
        ) : (
          <>
            {DocumentType === 'DIAG' ? DiagEditForm : LabEditForm}
            <Row>
              <Col sm={6} className='d-grid'>
                <Button
                  variant='warning'
                  size='lg'
                  onClick={() => {
                    navigate(generatePath(Routes.MedicalDocument.path, { id: data.id }));
                  }}
                  className='animate-up-2'
                >
                  {t('document-form.document-discard-changes-button')}
                </Button>
              </Col>
              <Col sm={6} className='d-grid'>
                <Button variant='logo-green' size='lg' type='submit' className='animate-up-2'>
                  {isLoadingUpdate || isLoadingCreate ? (
                    <Spinner />
                  ) : (
                    t('document-form.document-submit-button')
                  )}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
};
