import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ArrowNarrowLeftIcon, MailIcon } from '@heroicons/react/solid';
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Collapse,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { ErrorAlert } from '#atoms';
import { Routes } from '#Routes';
import { selectIsLogged, useResendConfirmationEmailMutation } from '#store';

const ResendEmailVerificationPage = () => {
  const { t, i18n } = useTranslation();
  const isLogged = useSelector(selectIsLogged);
  const patient = useSelector((state) => state.patient);
  const { register, handleSubmit } = useForm();
  const [resendConfirmEmail, { isLoading, isSuccess, isError, error: rawError }] =
    useResendConfirmationEmailMutation();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      // Any cleanup needed when component unmounts
      // This might need to dispatch a reset action if we have one
    };
  }, []);

  const error = useMemo(() => {
    if (!rawError) return undefined;
    if (rawError?.status === 400) {
      if (rawError.data?.detail) {
        if (
          rawError.data.detail === "Account with this email doesn't exist or is already verified."
        ) {
          return [t('errors.api.emailAlreadyVerified')];
        }
        return [rawError.data.detail];
      }
      if (rawError.data?.email) {
        const emailErrors = Array.isArray(rawError.data.email)
          ? rawError.data.email
          : [rawError.data.email];
        return emailErrors;
      }
      return Array.isArray(rawError.data) ? rawError.data : [String(rawError.data)];
    }

    return [t('errors.api.unexpectedError')];
  }, [rawError, t]);

  const onFormSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        language: i18n.language || 'pl'
      };
      await resendConfirmEmail(payload).unwrap();
    } catch (err) {
      // Error handling is already done through the RTK Query state
    }
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    // Force navigation to login and potentially clear any auth states that might be causing the loop
    navigate(Routes.Login.path, { replace: true });
  };

  return (
    <main>
      <section className='d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft'>
        <Container>
          <div className='text-center mb-4'>
            <Card.Link
              as='button'
              onClick={handleBackClick}
              className='d-inline-flex align-items-center border-0 bg-transparent'
            >
              <ArrowNarrowLeftIcon className='icon icon-xs me-2' />
              {isLogged ? t('auth.backToAccount') : t('auth.backToLogin')}
            </Card.Link>
          </div>
          <Row className='justify-content-center'>
            <Col xs={12} className='d-flex align-items-center justify-content-center'>
              <div className='bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                <h3>{t('auth.resendEmailVerification')}</h3>
                <p className='mb-4'>{t('auth.resendEmailVerificationDescription')}</p>

                <Form onSubmit={handleSubmit(onFormSubmit)}>
                  <div className='mb-4'>
                    <Form.Label htmlFor='email'>{t('auth.email')}</Form.Label>
                    <InputGroup id='email'>
                      <Form.Control
                        autoFocus
                        type='email'
                        placeholder='john@company.com'
                        autoComplete='email'
                        {...register('email', { required: true, value: patient.email })}
                      />
                    </InputGroup>
                  </div>

                  {isSuccess && (
                    <Collapse in={true} appear={true}>
                      <div className='d-grid'>
                        <Alert variant='success'>
                          {t(`auth.resendEmailVerificationSuccessPrompt`)}
                        </Alert>
                      </div>
                    </Collapse>
                  )}

                  {!isSuccess && (
                    <>
                      {isError && <ErrorAlert error={error} />}

                      <div className='d-grid'>
                        <Button variant='primary' size='lg' type='submit' disabled={isLoading}>
                          {isLoading ? (
                            <Spinner size='sm' />
                          ) : (
                            <>
                              <MailIcon className='icon icon-sm' />{' '}
                              {t('auth.resendEmailVerificationSubmit')}
                            </>
                          )}
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ResendEmailVerificationPage;
