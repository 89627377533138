import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Routes } from '#Routes';
import { authLogin, setPatient } from '#store';

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const login = async (credentials, redirectTo) => {
    try {
      const response = await dispatch(authLogin(credentials)).unwrap();
      
      if (response?.user) {
        dispatch(setPatient(response.user));
        const targetPath = redirectTo || Routes.Dashboard.path;
        const cleanPath = targetPath.replace(/^\/auth/, '');
        navigate(cleanPath);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
  };

  const redirectWhenLoggedIn = useCallback(
    (redirectTo) => {
      if (isAuthenticated) {
        const targetPath = redirectTo || Routes.Dashboard.path;
        const cleanPath = targetPath.replace(/^\/auth/, '');
        navigate(cleanPath);
        return true;
      }
      return false;
    },
    [isAuthenticated, navigate],
  );

  return { login, redirectWhenLoggedIn };
};

export default useLogin;
