import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, useParams, generatePath } from 'react-router-dom';
import { usePDF, Margin } from 'react-to-pdf';
import { useTranslation } from 'react-i18next';
import { useFetchHealthReportQuery } from '#store';
import { HealthReportActions } from '#components';
import { Document } from '#atoms';
import { Routes } from '#Routes';
import moment from 'moment';

const HealthReportPage = () => {
  const params = useParams();
  const { t } = useTranslation();

  const { data, isLoading } = useFetchHealthReportQuery(
    { id: params?.id },
    { refetchOnMountOrArgChange: true },
  );

  const { toPDF, targetRef } = usePDF({
    filename: `${t('health-report-page.filename')}-${params?.id}.pdf`,
    page: {
      margin: Margin.SMALL,
    },
  });

  return (
    <Row className='justify-content-center mt-4'>
      <Col xs={12} xl={9} className='position-relative'>
        {!isLoading && (
          <div
            className='px-0 text-end position-absolute z-3'
            style={{ top: '-20px', right: '30px' }}
          >
            <HealthReportActions displayButtons={true} data={data} toPDF={toPDF} />
          </div>
        )}

        <Document isLoading={isLoading} documentRef={targetRef}>
          <Document.Loader
            isLoading={data?.process_status === 'IN_PROGRESS'}
            text={t('health-report-page.processing')}
          />

          <Document.Title badge={{ bg: 'success', text: 'AI' }}>
            {t('health-report-page.title')}
          </Document.Title>

          <Document.Information>
            <>
              {data?.documents?.length && (
                <Col xs={12} className='mb-2'>
                  {t('health-report-page.medical-documents')} ({data.documents.length}):
                  <span className='fw-bold'>
                    {data.documents.map((id, index) => (
                      <Document.Link
                        key={id}
                        as={Link}
                        className='ms-2'
                        to={generatePath(Routes.MedicalDocument.path, { id })}
                      >
                        {id}
                        {index < data.documents.length - 1 ? ',' : ''}
                      </Document.Link>
                    ))}
                  </span>
                </Col>
              )}
              <Col xs={12} className='mb-2'>
                {t('health-report-page.processing-finished')}{' '}
                <span className='fw-bold'>
                  {data?.process_status === 'FINISHED'
                    ? t('health-report-page.yes')
                    : t('health-report-page.no')}
                </span>
              </Col>
            </>
            <>
              <Col xs={6} className='mb-2'>
                {t('health-report-page.date-created')}
              </Col>
              <Col xs={6} className='mb-2'>
                {moment(data?.created).format('y-MM-DD HH:mm')}
              </Col>
            </>
          </Document.Information>

          <Document.Subtitle>{data?.report_title}</Document.Subtitle>

          <Document.Text title={t('health-report-page.lab-report')} content={data?.report_labs} />

          <Document.Text title={t('health-report-page.diag-report')} content={data?.report_diags} />

          <Document.Text
            title={t('health-report-page.summary-report')}
            content={data?.report_final}
          />
          <div className='mt-4' style={{ textAlign: 'right' }}>{t('medical-document.document-not-approved')}</div>
        </Document>
      </Col>
    </Row>
  );
};

export default HealthReportPage;
